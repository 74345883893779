import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import React from 'react'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GOOGLE_RECAPTCHA_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
